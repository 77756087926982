@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

$navBG:#575757;
$primary: #f02964;
$dark: #1f1e1e;
$accent: #34d3eb;
$lightgrey: #585559;
$title-font: 'Dancing Script', cursive;

$theme-colors: (
  "primary": $primary,
  "danger": #ff4136,
  "accent": $accent
);



body {
    padding:0;
    margin:0;
    justify-content: center;
    
}

main{
    padding-bottom: 2rem;
}

ul{
    padding-left: 1rem;
}


.container{
    min-height: 100vh;
    background-color:$dark;
    max-width: 1600px;

}

.follow-close{
    transform:translateZ(0);
}

.nav-some{
    display:inline-block;
    margin-right: 0.5rem;
}

footer{
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
}

.divide{
    width: 20%;
    height: 50px;
    margin-top: -20px;
    border-bottom: 1px solid white;
    box-shadow: 0 20px 20px -20px #333;
    margin: -50px auto 10px;
}

main {
    margin-bottom: 50px;
    h2{
        color: $primary;
        font-family: $title-font;
        font-size: 40pt;
    }
    color:white;
}

.navbar {
    background-color: $navBG;
}

a {
    color: $primary;
    &:visited {
      color: $primary;
    }
    &:hover {
      color: $accent;   
    }
    &:active {
      color: $accent;
    }
}

.card{
    background-color: $lightgrey;
}

.readerContainer{
    background-color:$lightgrey;
}


.video-container {
    position: relative;
    margin-top: 10px;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
